import * as React from 'react'
import { Heading } from '@chakra-ui/react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import {
  FormControl,
  FormLabel,
  Button,
  Textarea,
  Input,
  VStack,
  Box,
  useColorModeValue
} from '@chakra-ui/react'

function Contact() {
    const textBg = useColorModeValue('blackAlpha.100','whiteAlpha.100')
    // const drop = useColorModeValue('whiteAlpha.100', 'blackAlpha.100')
  return (
    <Layout>
      <SEO
        title='Contact'
        keywords={['contact', 'composer', 'musician', 'coder']}
      />
      <Heading fontSize={["6xl",  "6xl", "8xl"]}>
        Contact
      </Heading>
      
      <Box  bg={textBg} padding={2} border={2} boxShadow="md" >
      <form method="post" action="https://www.flexyform.com/f/a57d67ace18dc5567a46d0e49b439ea9761853cd">
        <VStack>
          <FormControl id='name' isRequired>
            <FormLabel>Name</FormLabel>
            <Input type='text' name='fullname' />
          </FormControl>

          <FormControl id='email' isRequired>
            <FormLabel>Email</FormLabel>
            <Input type='email' name="_reply_to" />
          </FormControl>
          <FormControl id='subjejct' isRequired>
            <FormLabel>Subject</FormLabel>
            <Input type='text' name="subject" />
          </FormControl>
          <FormControl id='message' isRequired>
              <FormLabel>Message</FormLabel>
            <Textarea placeholder='Type your message here' name="message"/>
          </FormControl>
          <Input type="text" visibility="hidden" value="" name="_empty_field"/>
          <Button type='submit'>Submit</Button>
          <Input type="text" visibility="hidden" value="" name="_empty_field"/>
          
        </VStack>
      </form>
      </Box>
    </Layout>
  )
}

export default Contact
